import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

export const InterceptSkipErrorHeader = 'X-Skip-Error-Interceptor';

@Injectable({
  providedIn: 'root',
})
export class BlobHttpErrorInterceptorService implements HttpInterceptor {
  constructor() {
  }

  // https://stackoverflow.com/a/49047764
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptSkipErrorHeader)) {
      const headers = req.headers.delete(InterceptSkipErrorHeader);
      return next.handle(req.clone({headers}));
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.error instanceof Blob) {
          return new Promise<any>((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = (e) => {
              try {
                reject(new HttpErrorResponse({
                  error: JSON.parse(e.target.result as string),
                  headers: error.headers,
                  status: error.status,
                  statusText: error.statusText,
                  url: error.url || undefined,
                }));
              } catch (e) {
                reject(error);
              }
            };
            fr.onerror = (_) => {
              reject(error);
            };
            fr.readAsText(error.error);
          });
        }
        throw error;
      }),
    );
  }

}
