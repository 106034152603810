import {Component, Input, isDevMode, OnDestroy, OnInit} from '@angular/core';

import {NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {combineLatest, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../@core/data/users.service';
import {AnalyticsService} from '../../../@core/utils/analytics.service';
import {LOGOUT_TITLE} from '../../../pages/pages-menu';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {NbAccessChecker} from '@nebular/security';
import {LayoutService} from '../../../@core/utils/layout.service';
import {ErrorService} from '../../../pages/shared/errors/error.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DepartmentGridService} from '../../../pages/admin/data-service/department-grid.service';
import {Department} from '../../../../resourcewerx-api';

interface DepartmentMenuItem {
  name: string;
  departmentId: string;
  route: string;
  state?: object;
}

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() position = 'normal';

  private destroy$ = new Subject<void>();
  userPictureOnly: boolean = false;

  user: any;
  isViewAndProfileGranted: boolean = false;

  envProfile = environment.environment;

  isDevMode = isDevMode();

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'corporate';

  departmentMenuActions: Array<DepartmentMenuItem> = [
    {
      name: 'View Corporate',
      departmentId: null,
      route: 'pages/all',
    },
  ];
  departmentMenuItems: Array<DepartmentMenuItem> = [...this.departmentMenuActions];
  selectedDepartment: DepartmentMenuItem = this.departmentMenuItems[0];
  userMenu: NbMenuItem[] = [
    {title: 'Profile', url: 'https://portal.office.com/account/#personalinfo', target: '_blank'},
    {title: LOGOUT_TITLE},
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private departmentGridService: DepartmentGridService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public accessChecker: NbAccessChecker,
    private analyticsService: AnalyticsService,
    public errorService: ErrorService) {
  }

  navigateToDepartment(departmentMenuItem: DepartmentMenuItem) {
    let sidePanelRouteSegment: string;
    const {route} = departmentMenuItem;
    const {state} = departmentMenuItem;
    const isProjectMenuAction = this.departmentMenuActions.find(
      (projectMenuAction) => projectMenuAction.route === departmentMenuItem.route
    );

    // if (!isProjectMenuAction) {
      sidePanelRouteSegment =
        '/' + window.location.pathname.split('/').slice(3).join('/');
    // }

    this.router.navigate([route + sidePanelRouteSegment], {state}).then();

    setTimeout(() => {
      this.selectedDepartment =
        departmentMenuItem.route === this.departmentMenuActions[0].route
          ? this.departmentMenuActions[0]
          : departmentMenuItem;
    });
  }

  ngOnInit() {
    this.userService.onUserChange().pipe(
      takeUntil(this.destroy$),
      switchMap((user: any) => {
        this.user = user;
        return this.accessChecker
          .isGranted('view', 'profile')
          .pipe(take(1), takeUntil(this.destroy$));
      })
    ).subscribe(e => {
      this.isViewAndProfileGranted = e;
    });

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        takeUntil(this.destroy$),
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        takeUntil(this.destroy$),
        map(({name}) => name),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    combineLatest([
      this.route.paramMap,
      this.departmentGridService.queryAll({
        skip: 0,
        take: 10,
      }),
    ]).subscribe({
      next: ([params, gridDataResult]) => {
        const departments: Array<Department> = gridDataResult.data;
        const departmentMenuItems: Array<DepartmentMenuItem> = [
          ...this.departmentMenuActions,
        ];
        const departmentId = params.get('departmentId');
        let selectedDepartment: DepartmentMenuItem | undefined;

        departments.forEach((department) => {
          const departmentsId = department.id?.toString();

          departmentMenuItems.push({
            name: `${department.name}`,
            departmentId: departmentsId || 'all',
            route: `pages/${departmentsId}`,
          });
        });

        this.departmentMenuItems = departmentMenuItems;

        if (departmentId) {
          selectedDepartment = this.departmentMenuItems.find(
            (departmentMenuItem) => departmentMenuItem.departmentId === departmentId
          );
        }

        if (selectedDepartment) {
          this.selectedDepartment = selectedDepartment;
        } else {
          this.selectedDepartment = this.departmentMenuActions[0];
        }

        this.departmentMenuActions[0].name =
          departmentId !== 'all' ? 'View Corporate' : 'Corporate';
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
    return false;
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  displayErrorAlerts() {
    this.errorService.notificationButtonClicked = true;
    this.errorService.displayAlerts();
  }

}
