/**
 * ResourceWerx API
 * An API for ResourceWerx Document Management System.
 *
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DateValueType = 'TaskStart' | 'TaskEnd' | 'Milestone';

export const DateValueType = {
    TaskStart: 'TaskStart' as DateValueType,
    TaskEnd: 'TaskEnd' as DateValueType,
    Milestone: 'Milestone' as DateValueType
};

