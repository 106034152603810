<div class="header-container"
     [class.left]="position === 'normal'"
     [class.right]="position === 'inverse'">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="navigation"><i class="nb-menu"></i></a>
    <div class="logo" (click)="goToHome()">Resource<span>Werx</span></div>
  </div>
  <!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
  <!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
  <!--  </nb-select>-->
  <div>
    <kendo-dropdownlist
      [data]="departmentMenuItems"
      class="project-menu-button"
      textField="name"
      valueField="route"
      [popupSettings]="{ popupClass: 'project-menu-popup', animate: true, width: 'auto' }"
      [(ngModel)]="selectedDepartment"
      (selectionChange)="navigateToDepartment($event)">
    </kendo-dropdownlist>
  </div>
  <div class="font-w-bold text-uppercase ms-2" [ngClass]="{'text-success': isDevMode, 'text-danger': !isDevMode}"
       *ngIf="envProfile != null && envProfile.trim().length > 0">
    {{ envProfile }}
  </div>
</div>

<div class="header-container">

  <nb-actions
    size="medium"
    [class.right]="position === 'normal'"
    [class.left]="position === 'inverse'">
    <nb-action icon="bell"
               [badgeText]="errorService.alerts.length > 0 ? '!' : null"
               [badgeStatus]="errorService.alerts.length > 0 ? 'danger' : 'basic'"
               (click)="displayErrorAlerts()">
    </nb-action>
    <!--<nb-action *ngIf="isDevMode" icon="nb-gear" class="toggle-layout" (click)="toggleSettings()"></nb-action>-->
    <nb-action *ngIf="isViewAndProfileGranted">
      <nb-user [nbContextMenu]="userMenu" [name]="user?.name" [onlyPicture]="userPictureOnly"
               [picture]="user?.picture"></nb-user>
    </nb-action>
    <!--<nb-action class="control-item" disabled icon="nb-notifications"></nb-action>-->
    <!--<nb-action class="control-item" icon="nb-email"></nb-action>-->
    <!--<nb-action class="control-item">-->
    <!--<nb-search type="rotate-layout" (click)="startSearch()"></nb-search>-->
    <!--</nb-action>-->
  </nb-actions>
</div>

