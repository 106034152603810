import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ErrorService} from '../../pages/shared/errors/error.service';
import {BatchResponse} from '../../pages/shared/odata-batch/batch-response.model';
import {ValidationProblemDetails} from '../../../resourcewerx-api';

export const InterceptSkipErrorHeader = 'X-Skip-Error-Interceptor';
@Injectable({
  providedIn: 'root',
})
export class GlobalHttpBatchErrorInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector, private errorService: ErrorService) {
  }

  // https://stackoverflow.com/a/49047764
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptSkipErrorHeader)) {
      const headers = req.headers.delete(InterceptSkipErrorHeader);
      return next.handle(req.clone({headers}));
    }
    return next.handle(req).pipe(
      tap(event =>  {
        if(event.type === HttpEventType.Response) {
          if(event.body?.hasOwnProperty('responses')) {
            const hasFailures = event.body.responses.some((response: BatchResponse) => {
              return response.status === 400;
            });
            event.body.responses.forEach((response: BatchResponse) => {
              if (response.status === 400) {
                const error: ValidationProblemDetails = {
                  error: response.body,
                  status: response.status,
                };
                this.errorService.next(error);
              }
            });
            // if (hasFailures) {
            //   return throwError(() => 'One or more requests failed.');
            // }
            return event;
          }
        }
      }),
    );
  }
}
