import { Injectable } from '@angular/core';
import {TableService} from '@framewerx/kendo-angular-grid';
import {Department, DepartmentService} from '../../../../resourcewerx-api';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DepartmentGridService extends TableService<Department> {

  constructor(http: HttpClient, private clientService: DepartmentService) {
    super(http, 'Department');
  }

  getIdUrlPart(data: any | Department): string {
    if (data == null) {
      return '';
    }
    return `/${ data.id }`;
  }

  add(data: any | Department): Observable<any> {
    return this.clientService.departmentPost(this.cleanupItem(data));
  }

  delete(data: any | Department): Observable<any> {
    return this.clientService.departmentDelete(data.id);
  }

  update(data: any | Department): Observable<any> {
    return this.clientService.departmentPatch(this.cleanupItem(data), data.id);
  }

  protected cleanupItem(dataItem: any): any {
    delete dataItem.color;
    return super.cleanupItem(dataItem);
  }

  protected mapToObject(responseData: any | Department): Department {
    // responseData.color = Color.fromRGB(responseData.color);
    return responseData;
  }
}
