import {Component, InjectionToken, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AnalyticsService} from './@core/utils/analytics.service';
import {KendoAngularGridConfig} from '@framewerx/kendo-angular-grid';
import {NGXLogger} from 'ngx-logger';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthenticationResult, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {MsalBroadcastService, MsalCustomNavigationClient, MsalService} from '@azure/msal-angular';
import {Subject} from 'rxjs';
import {UserService} from './@core/data/users.service';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {ErrorResponse} from '../resourcewerx-api';
import {ErrorService} from './pages/shared/errors/error.service';
import {NbIconLibraries} from '@nebular/theme';

export const KENDO_ANGULAR_GRID_CONFIG = new InjectionToken<KendoAngularGridConfig>('KENDO_ANGULAR_GRID_CONFIG');


@Component({
  selector: 'ngx-app',
  template: `
      <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit, OnDestroy {

  appVersion = '';
  alerts: Array<ErrorResponse | any> = [];
  protected destroy$ = new Subject<boolean>();

  private myInterval;

  constructor(
    private logger: NGXLogger,
    private msalBroadcastService: MsalBroadcastService,
    //private environmentService: EnvironmentService,
    private userService: UserService,
    private authService: MsalService,
    private router: Router,
    private location: Location,
    private analytics: AnalyticsService,
    public errorService: ErrorService,
    public iconLibraries: NbIconLibraries,
  ) {

    // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/performance.md
    const customNavigationClient = new MsalCustomNavigationClient(this.authService, this.router, this.location);
    this.authService.instance.setNavigationClient(customNavigationClient);

    this.iconLibraries.registerFontPack('fas', {packClass: 'fas', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('far', {packClass: 'far', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('fab', {packClass: 'fab', iconClassPrefix: 'fa'});
    this.iconLibraries.setDefaultPack('fas');

  }

  saveAuthToken(payload: AuthenticationResult) {
    this.authService.instance.setActiveAccount(payload.account);

    // Save the token to Cookie
    let date = null;
    // @ts-ignore
    if (payload?.idTokenClaims?.exp != null) date = new Date(payload.idTokenClaims.exp * 1000);
    this.userService.saveIdToken(payload?.idToken, date);

    this.userService.publishUser(payload.account.idTokenClaims);
  }

  ngOnInit() {
    this.analytics.trackPageViews();

    // run it without delay for first time
    // this.checkAppVersion();

    // checking for app Version every 30 minutes.
    // this.myInterval = setInterval(() => {
    //   this.checkAppVersion();
    // }, 1800 * 1000);

    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this.destroy$),
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe((result) => {
        this.logger.trace('inProgress$: ' + JSON.stringify(result));
        this.checkAndSetActiveAccount();
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this.destroy$),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.logger.trace('msal:loginSuccess: ' + JSON.stringify(payload));
        this.saveAuthToken(payload);
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this.destroy$),
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      ).subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        // this.logger.trace('msal:acquireTokenSuccess: ' + JSON.stringify(payload));
        if (payload.fromCache === false && payload.authority === environment.authority) {
          this.saveAuthToken(payload);
          this.logger.trace('msal:acquireTokenSuccess: updated local service auth token and cookie!');
        }
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.myInterval);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/b00e5be121a97e8ad5a610a21175b05e6858c3ac/samples/msal-angular-v2-samples/angular12-sample-app/src/app/app.component.ts#L43
  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      this.logger.trace('checkAndSetActiveAccount: No active account set but active accounts present...');
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.userService.publishUser(accounts[0].idTokenClaims);
      this.logger.trace('checkAndSetActiveAccount: Active account set to: ' + JSON.stringify(accounts[0]));
    }
  }

  // private checkAppVersion() {
  //   this.environmentService.environmentGet()
  //     .subscribe((e) => {
  //       this.appVersion = e.appVersion;
  //       const envAppVersion = environment.appVersion;
  //       const diff = (this.appVersion).localeCompare(envAppVersion);
  //       if (diff > 0) {
  //         this.alerts.push({message: JSON.stringify('Newer version available. Update by using Reset App in the left-side menu.'), statusCode: 199});
  //         this.errorService.setAlerts(this.alerts);
  //         this.errorService.displayAlerts();
  //
  //       }
  //     });
  // }

}
