import {Injectable} from '@angular/core';
import {PreloadingStrategy, Route} from '@angular/router';
import {Observable, of, timer} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Injectable()
export class ProgressPreloadingStrategy implements PreloadingStrategy {

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      const loadRoute = (delay) => delay
        ? timer(3000).pipe(mergeMap(() => load()))
        : load();
      return loadRoute(route.data.delay);
    } else {
      return of(null);
    }
  }

}
