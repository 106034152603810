import {Component, Input, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-logout-prompt',
  template: `
    <nb-card class="dialog-card nb-layout-column">
      <nb-card-header class="h3">
        {{title}}
        <button (click)="dismiss()"
                type="button"
                class="btn btn-danger float-end ms-3">
          <span class="fas fa-times fa-2x"></span>
        </button>
      </nb-card-header>
      <nb-card-body class="h4" style="white-space: pre-line; overflow-y: auto; overflow-x: auto;">
        {{body}}
      </nb-card-body>
      <nb-card-footer>
        <div [ngClass]="{'d-grid gap-3': isStackButtons === true}">
          <button *ngIf="cancelText != null" [class]="cancelClass" (click)="cancelClick()">
            {{cancelText}}
          </button>
          <button *ngIf="confirmText != null" [class]="confirmClass" (click)="confirmClick()">
            {{confirmText}}
          </button>
        </div>
      </nb-card-footer>
    </nb-card>
  `,
  styleUrls: ['./dialog-common.scss'],
})
export class ConfirmPromptComponent {

  @Input() title: string;
  @Input() body: string;
  @Input() confirmText: string;
  @Input() confirmClass: string = 'btn btn-danger float-start';
  @Input() cancelText: string;
  @Input() cancelClass: string = 'btn btn-info float-end';
  /// Set this value to true if you want the buttons to be stacked on top of each other and full width
  @Input() isStackButtons: boolean = false;

  constructor(@Optional() protected dialogRef: NbDialogRef<ConfirmPromptComponent>) {
  }

  confirmClick() {
    this.dialogRef.close(true);
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

  dismiss() {
    this.dialogRef.close(undefined);
  }

}
