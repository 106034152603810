/**
 * ResourceWerx API
 * An API for ResourceWerx Document Management System.
 *
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DataValueType = 'Date' | 'Decimal' | 'String' | 'Lookup';

export const DataValueType = {
    Date: 'Date' as DataValueType,
    Decimal: 'Decimal' as DataValueType,
    String: 'String' as DataValueType,
    Lookup: 'Lookup' as DataValueType
};

