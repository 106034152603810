export * from './calendar.service';
import { CalendarService } from './calendar.service';
export * from './changeOrder.service';
import { ChangeOrderService } from './changeOrder.service';
export * from './department.service';
import { DepartmentService } from './department.service';
export * from './departmentResourceSpace.service';
import { DepartmentResourceSpaceService } from './departmentResourceSpace.service';
export * from './departmentResourceSpaceResource.service';
import { DepartmentResourceSpaceResourceService } from './departmentResourceSpaceResource.service';
export * from './departmentResourceSpaceResourceLevel.service';
import { DepartmentResourceSpaceResourceLevelService } from './departmentResourceSpaceResourceLevel.service';
export * from './departmentResourceSpaceResourceLevelDetail.service';
import { DepartmentResourceSpaceResourceLevelDetailService } from './departmentResourceSpaceResourceLevelDetail.service';
export * from './departmentResourceSpaceResourceRecord.service';
import { DepartmentResourceSpaceResourceRecordService } from './departmentResourceSpaceResourceRecord.service';
export * from './departmentResourceType.service';
import { DepartmentResourceTypeService } from './departmentResourceType.service';
export * from './departmentTask.service';
import { DepartmentTaskService } from './departmentTask.service';
export * from './departmentTaskGroup.service';
import { DepartmentTaskGroupService } from './departmentTaskGroup.service';
export * from './departmentWorkPackage.service';
import { DepartmentWorkPackageService } from './departmentWorkPackage.service';
export * from './departmentWorkPackageType.service';
import { DepartmentWorkPackageTypeService } from './departmentWorkPackageType.service';
export * from './environment.service';
import { EnvironmentService } from './environment.service';
export * from './formulaItem.service';
import { FormulaItemService } from './formulaItem.service';
export * from './hoursExpended.service';
import { HoursExpendedService } from './hoursExpended.service';
export * from './location.service';
import { LocationService } from './location.service';
export * from './manPower.service';
import { ManPowerService } from './manPower.service';
export * from './manPowerDetail.service';
import { ManPowerDetailService } from './manPowerDetail.service';
export * from './manPowerHoursOff.service';
import { ManPowerHoursOffService } from './manPowerHoursOff.service';
export * from './metadata.service';
import { MetadataService } from './metadata.service';
export * from './noWorkDay.service';
import { NoWorkDayService } from './noWorkDay.service';
export * from './nonWorkDay.service';
import { NonWorkDayService } from './nonWorkDay.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './projectBreakdown.service';
import { ProjectBreakdownService } from './projectBreakdown.service';
export * from './projectBreakdownType.service';
import { ProjectBreakdownTypeService } from './projectBreakdownType.service';
export * from './projectStatus.service';
import { ProjectStatusService } from './projectStatus.service';
export * from './projectTask.service';
import { ProjectTaskService } from './projectTask.service';
export * from './projectType.service';
import { ProjectTypeService } from './projectType.service';
export * from './projectWorkPackage.service';
import { ProjectWorkPackageService } from './projectWorkPackage.service';
export * from './projectWorkPackagePerformance.service';
import { ProjectWorkPackagePerformanceService } from './projectWorkPackagePerformance.service';
export * from './projectWorkPackagePerformanceResource.service';
import { ProjectWorkPackagePerformanceResourceService } from './projectWorkPackagePerformanceResource.service';
export * from './projectWorkPackageResource.service';
import { ProjectWorkPackageResourceService } from './projectWorkPackageResource.service';
export * from './resourceType.service';
import { ResourceTypeService } from './resourceType.service';
export * from './scheduleRequirement.service';
import { ScheduleRequirementService } from './scheduleRequirement.service';
export * from './scheduleRequirementType.service';
import { ScheduleRequirementTypeService } from './scheduleRequirementType.service';
export * from './scheduleRequirementTypeOverride.service';
import { ScheduleRequirementTypeOverrideService } from './scheduleRequirementTypeOverride.service';
export * from './workPackage.service';
import { WorkPackageService } from './workPackage.service';
export * from './workPackagePerformance.service';
import { WorkPackagePerformanceService } from './workPackagePerformance.service';
export const APIS = [CalendarService, ChangeOrderService, DepartmentService, DepartmentResourceSpaceService, DepartmentResourceSpaceResourceService, DepartmentResourceSpaceResourceLevelService, DepartmentResourceSpaceResourceLevelDetailService, DepartmentResourceSpaceResourceRecordService, DepartmentResourceTypeService, DepartmentTaskService, DepartmentTaskGroupService, DepartmentWorkPackageService, DepartmentWorkPackageTypeService, EnvironmentService, FormulaItemService, HoursExpendedService, LocationService, ManPowerService, ManPowerDetailService, ManPowerHoursOffService, MetadataService, NoWorkDayService, NonWorkDayService, ProjectService, ProjectBreakdownService, ProjectBreakdownTypeService, ProjectStatusService, ProjectTaskService, ProjectTypeService, ProjectWorkPackageService, ProjectWorkPackagePerformanceService, ProjectWorkPackagePerformanceResourceService, ProjectWorkPackageResourceService, ResourceTypeService, ScheduleRequirementService, ScheduleRequirementTypeService, ScheduleRequirementTypeOverrideService, WorkPackageService, WorkPackagePerformanceService];
