import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NbAlertModule, NbIconModule} from '@nebular/theme';
import {DateInputsModule, DatePickerModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {DropDownListModule, SharedModule as KendoSharedModule} from '@progress/kendo-angular-dropdowns';
import {ExcelModule, GridModule, PDFModule} from '@progress/kendo-angular-grid';
import {NumericTextBoxModule, SliderModule} from '@progress/kendo-angular-inputs';
import {IntlModule} from '@progress/kendo-angular-intl';
import {SchedulerModule} from '@progress/kendo-angular-scheduler';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../../../environments/environment';
import {ThemeModule} from '../../@theme/theme.module';
import {ApplicationPipesModule} from '../../pipes/application-pipes.module';
import {AdminHeaderContainerComponent} from './admin-header-container/admin-header-container.component';
import {BackComponent} from './back/back.component';
import {DropDownListFilterComponent} from './drop-down-list-filter/drop-down-list-filter.component';
import {DropDownListMenuFilterComponent} from './drop-down-list-filter/drop-down-list-menu-filter.component';
import {ViewErrorsComponent} from './errors/view-errors/view-errors.component';
import {SimpleRouterOutletComponent} from './simple-router-outlet/simple-router-outlet.component';
import {TimeWrapperComponent} from './time-wrapper/time-wrapper.component';
import {KendoAngularGridConfig, KendoAngularGridModule} from '@framewerx/kendo-angular-grid';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {MassEditorComponent} from './mass-editor/mass-editor.component';
import { GridCustomViewCellComponent } from './grid-custom-components/grid-custom-view-cell/grid-custom-view-cell.component';
import { GridCustomEditCellComponent } from './grid-custom-components/grid-custom-edit-cell/grid-custom-edit-cell.component';
import {TreeListModule, PDFModule as TreePDFModule, ExcelModule as TreeExcelModule} from '@progress/kendo-angular-treelist';
import { TreeListComponent } from './tree/tree-list/tree-list.component';
import {SVGIconModule} from '@progress/kendo-angular-icons';
import {CommonModule} from '@framewerx/common';
import {GanttModule} from '@progress/kendo-angular-gantt';
import {TooltipsModule} from '@progress/kendo-angular-tooltip';
import { ImportFileSelectorComponent } from './import-file-selector/import-file-selector.component';
import {NonWorkDayComponent} from './non-work-day/non-work-day.component';
import {FormulaEditorComponent} from './formula-editor/formula-editor.component';
import {TreeViewModule} from '@progress/kendo-angular-treeview';
import {PanelBarModule} from '@progress/kendo-angular-layout';
import {TreeLegendComponent} from './legends/legend.component';

export function kendoGridConfigFactory(): KendoAngularGridConfig {
  return {apiRootUrl: `${environment.apiBaseUrl}`} as KendoAngularGridConfig;
}

@NgModule({
  imports: [
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs', // TODO: Implement logging in server https://stackoverflow.com/a/53078568/2557554
      level: environment.loggingLevel,
      // serverLogLevel: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    ThemeModule,
    GridModule,
    TreeListModule,
    TreeViewModule,
    SliderModule,
    DialogModule,
    DatePickerModule,
    TimePickerModule,
    IntlModule,
    ApplicationPipesModule,
    NbAlertModule,
    DateInputsModule,
    SchedulerModule,
    DropDownListModule,
    RouterModule,
    NumericTextBoxModule,
    KendoAngularGridModule,
    ButtonModule,
    NbIconModule,
    SVGIconModule,
    CommonModule,
    PDFModule,
    ExcelModule,
    TreePDFModule,
    TreeExcelModule,
    GanttModule,
    TooltipsModule,
    KendoSharedModule,
    SchedulerModule,
    PanelBarModule,
  ],
  declarations: [
    ViewErrorsComponent,
    BackComponent,
    TimeWrapperComponent,
    AdminHeaderContainerComponent,
    DropDownListFilterComponent,
    DropDownListMenuFilterComponent,
    SimpleRouterOutletComponent,
    MassEditorComponent,
    GridCustomViewCellComponent,
    GridCustomEditCellComponent,
    TreeListComponent,

    ImportFileSelectorComponent,
    NonWorkDayComponent,
    FormulaEditorComponent,
    TreeLegendComponent
  ],
  providers: [],
  exports: [
    ViewErrorsComponent,
    BackComponent,
    TimeWrapperComponent,
    AdminHeaderContainerComponent,
    DropDownListFilterComponent,
    DropDownListMenuFilterComponent,
    SimpleRouterOutletComponent,
    MassEditorComponent,
    GridCustomViewCellComponent,
    GridCustomEditCellComponent,
    TreeListComponent,
    NonWorkDayComponent,
    FormulaEditorComponent,
    TreeLegendComponent
  ],
})
export class SharedModule {
}
