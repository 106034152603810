/**
 * ResourceWerx API
 * An API for ResourceWerx Document Management System.
 *
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ImportAction = 'append' | 'appendUpdate' | 'overwrite';

export const ImportAction = {
    Append: 'append' as ImportAction,
    AppendUpdate: 'appendUpdate' as ImportAction,
    Overwrite: 'overwrite' as ImportAction
};

