/**
 * ResourceWerx API
 * An API for ResourceWerx Document Management System.
 *
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FormulaItemType = 'Operator' | 'Function' | 'Variable' | 'Constant';

export const FormulaItemType = {
    Operator: 'Operator' as FormulaItemType,
    Function: 'Function' as FormulaItemType,
    Variable: 'Variable' as FormulaItemType,
    Constant: 'Constant' as FormulaItemType
};

