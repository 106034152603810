import {NbMenuItem} from '@nebular/theme';

export const LOGOUT_TITLE: string = 'Logout';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home',
    link: '/pages/{departmentId}/dashboard',
    home: true,
  },
  // {
  //   title: 'Help',
  //   icon: 'question-circle',
  //   url: 'https://framewerx.atlassian.net/wiki/spaces/DM/overview',
  //   target: '_blank',
  // },
  // {
  //   title: 'UTILITIES',
  //   group: true,
  // },
  // {
  //   title: 'Reset App',
  //   icon: 'redo',
  //   link: '/pages/{departmentId}/dashboard/reset',
  // },
];

export const SUBMENU_ITEMS: NbMenuItem[] = [
  {
    title: 'UTILITIES',
    group: true,
  },
  {
    title: 'Help',
    icon: 'question-circle',
    url: 'https://framewerx.atlassian.net/wiki/spaces/DM/overview',
    target: '_blank',
  },
  {
    title: 'Reset App',
    icon: 'redo',
    link: '/pages/{departmentId}/dashboard/reset',
  },
];

export const AUTH_ITEMS: NbMenuItem[] = [
  {
    title: 'AUTHENTICATION',
    group: true,
  },
  {
    title: 'Login',
    icon: 'sign-in-alt',
    link: '/pages/auth/login',
    data: {
      permission: 'public',
      resource: 'login',
    },
  },
  {
    title: LOGOUT_TITLE,
    icon: 'sign-out-alt',
    data: {
      permission: 'view',
      resource: 'usersonly',
    },
  },
];
