/**
 * ResourceWerx API
 * An API for ResourceWerx Document Management System.
 *
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RequirementSet = 'Contract' | 'Breakdown' | 'Department' | 'ChangeOrder';

export const RequirementSet = {
    Contract: 'Contract' as RequirementSet,
    Breakdown: 'Breakdown' as RequirementSet,
    Department: 'Department' as RequirementSet,
    ChangeOrder: 'ChangeOrder' as RequirementSet
};

